import { http } from '@/http/axios.js'

export const API_GET_DICT_TYPE_LIST = '/system/dict/type/list'

// 字典列表
export function getDictListAPI(params) {
  return http({
    url: `/admin/sys/dict/page`,
    method: 'get',
    params
  })
}

// 字典详情
export function getDictDetail(id) {
  return http({
    url: `/admin/sys/dict/detail`,
    method: 'get',
    params: { id }
  })
}
// 编辑字典
export function editDict(data) {
  return http({
    url: `/admin/sys/dict/edit`,
    method: 'POST',
    data
  })
}
// 新增字典
export function addDict(data) {
  return http({
    url: `/admin/sys/dict/add`,
    method: 'post',
    data
  })
}
// 删除字典
export function delDict(id) {
  return http({
    url: `/admin/sys/dict/delete`,
    method: 'post',
    data: { id }
  })
}
// 获取字典值列表
export function getDictValues(dictCode) {
  return http({
    url: `/admin/sys/dictEnum/list`,
    method: 'get',
    params: { dictCode }
  })
}
// 新增字典值
export function addDictValue(data) {
  return http({
    url: `/admin/sys/dictEnum/add`,
    method: 'post',
    data
  })
}
// 编辑字典值
export function editDictValue(data) {
  return http({
    url: `/admin/sys/dictEnum/edit`,
    method: 'POST',
    data
  })
}
// 删除字典值
export function delDictValue(id) {
  return http({
    url: `/admin/sys/dictEnum/delete`,
    method: 'POST',
    data: { id }
  })
}
// 字典值详情
export function getDictValue(id) {
  return http({
    url: `/admin/sys/dictEnum/detail`,
    method: 'GET',
    params: { id }
  })
}
